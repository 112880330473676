import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";
import { BETSHOP_SYSTEM, ONLINE_SYSTEM } from "src/app/constants";

const api_domain = jwtServiceConfig.apiUrl;

export const getSystemSettings = createAsyncThunk("sportsApp/getSettings", async (data) => {
  const response = await axios.get(`${api_domain}system/getSettings`);
  return response.data.system;
});

export const getLiveAgent = createAsyncThunk("sportsApp/getLiveAgent", async (data) => {
  const response = await axios.get(`${api_domain}liveagent`);
  return response;
});

const systemAdapter = createEntityAdapter([]);

/*export const {
  selectAll: selectSystemSettings,
} = systemAdapter.getSelectors((state) => state.systemSettings);*/

const initialState = {
  type: ONLINE_SYSTEM,
  // type: BETSHOP_SYSTEM,
  theme_name: "light",
  timezone: "GMT(+3)",
  user_currencies: null,
  languages: null,
  date_format: "DD/MM/YYYY",
  price_format: "decimal",
};

const systemSettingsSlice = createSlice({
  name: "sportsApp/system",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSystemSettings.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export const selectSystem = ({ sportsApp }) => sportsApp.systemSettings;

export default systemSettingsSlice.reducer;
