import React, { useEffect, useState } from "react";
import {
  Box,
  Modal,
  Typography,
  Stack,
  IconButton,
  TextField,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Input,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentURL, getDepositURL } from "../main/store/uiSlice";
import IFrameModal from "./IFrameModal";

const DepositModal = ({
  item,
  open,
  handleBack,
  depositMethod,
  currency = "€",
  depositDialogLimits,
  bonusMode,
}) => {
  const [loading, setLoading] = useState(false);
  const { logo } = depositMethod;
  const [selectedAmount, setSelectedAmount] = useState(null); // Tracks the selected preset button
  const [customAmount, setCustomAmount] = useState(""); // Tracks the input field amount
  const [accountEmail, setAccountEmail] = useState("");
  const [iFrameModalOpen, setIFrameModalOpen] = useState(false);
  const [iFrameUrl, setIFrameUrl] = useState('');

  const minAmount =
    depositDialogLimits?.min != undefined ? depositDialogLimits?.min : 10;
  const maxAmount =
    depositDialogLimits?.max != undefined ? depositDialogLimits?.max : 5000;
  const presetAmounts = [
    minAmount,
    minAmount * 2,
    minAmount * 5,
    minAmount * 10,
    minAmount * 20,
    minAmount * 50,
    minAmount * 100,
  ];
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    setAccountEmail("");
    setCustomAmount("");
    setSelectedAmount(null);

    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [open]);

  const handleAmountSelect = (amount) => {
    setSelectedAmount(amount);
    setCustomAmount(amount.toString()); // Populate the input field with the selected amount
  };

  const handleCustomAmountChange = (e) => {
    setCustomAmount(e.target.value);
    setSelectedAmount(null); // Clear selected button when custom input is modified
  };

  const handleDepositCoin = (amount, bonusMode, item) => {
    console.log(depositMethod.id, 'depositMethod')
    console.log(depositDialogLimits, 'depositMethod')
    /*dispatch(getPaymentURL({ coin: depositMethod.id, amount, bonusMode })).then(
      (res) => {
        const data = res.payload;
        // setIFrameUrl(data.result.checkout_url)
        window.open(data.result.checkout_url);
        setIFrameModalOpen(false);
      }
    );*/
    dispatch(getDepositURL({ gateway: depositMethod.id, bonusMode, amount })).then(
        (res) => {
          const data = res.payload;
          if (data.url != undefined) {
            window.open(data.url);
          }
        }
    );
  };

  return (
    <>
      {!iFrameModalOpen && (
        <Modal
          open={open}
          onClose={handleBack}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Stack className="w-full max-w-[500px] min-h-[300px] sm:h-auto h-screen bg-[#0D1D34] items-center rounded-[8px] p-[16px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute">
            {/* Header */}
            <Stack className="flex-row justify-between items-center w-full">
              <IconButton onClick={handleBack}>
                <ArrowBackIosIcon className="text-white" />
              </IconButton>
              <IconButton onClick={handleBack}>
                <CloseIcon className="text-white" />
              </IconButton>
            </Stack>

            {/* Title */}
            <Typography className="text-white text-[18px] text-center uppercase">
              Deposit
            </Typography>

            {/* Content */}
            {loading ? (
              <Box className="loader-expand mt-[50px]" />
            ) : (
              <Stack className="mt-[32px] gap-[24px] items-center w-full pb-[16px] px-[20px]">
                {/* Logo */}
                <Box className="min-w-[110px] h-[60px] bg-white p-[12px] rounded-[4px] shadow-md">
                  <img
                    src={logo}
                    alt="Logo"
                    className="w-full h-full object-cover"
                  />
                </Box>

                {/* Preset Amount Buttons */}
                <Box
                  className="grid gap-[12px] w-full"
                  style={{
                    gridTemplateColumns: "repeat(3, 1fr)", // Exactly 3 buttons per row
                  }}
                >
                  {presetAmounts.map((amount) => (
                    <Button
                      key={amount}
                      className={`h-[40px] hover:bg-[#4DA533] rounded-[4px] text-white ${selectedAmount === amount
                          ? "bg-[#4DA533]"
                          : "bg-[#3D4A5D]"
                        }`}
                      onClick={() => handleAmountSelect(amount)}
                    >
                      {amount} {currency}
                    </Button>
                  ))}
                </Box>

                {/* Custom Amount Input */}
                <FormControl
                  fullWidth
                  variant="standard"
                  sx={{
                    m: 1,
                    "& .MuiInputLabel-root": {
                      color: "#B0B0B0", // Default floating label color
                      fontSize: "20px",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#4DA533", // Floating label color when focused
                    },
                    "& .MuiInput-root:before": {
                      borderBottom: "1px solid #B0B0B0", // Default input border color
                    },
                    "& .MuiInput-root:after": {
                      borderBottom: "2px solid #4DA533", // Input border color when focused
                    },
                    "& .MuiInput-root:hover:not(.Mui-disabled):before": {
                      borderBottom: "1px solid #4DA533", // Input border color on hover
                    },
                  }}
                >
                  <InputLabel
                    htmlFor="standard-adornment-amount"
                    className="text-[16px] text-[#B0B0B0]"
                  >
                    Amount
                  </InputLabel>
                  <Input
                    id="standard-adornment-amount"
                    startAdornment={
                      <InputAdornment position="start">
                        <Typography className="text-[#4DA533] text-[40px] font-bold">
                          {currency}
                        </Typography>
                      </InputAdornment>
                    }
                    inputProps={{
                      className:
                        "text-[#4DA533] px-[12px] text-[40px] border-red",
                    }}
                    value={customAmount}
                    onChange={handleCustomAmountChange}
                  />
                  <Stack className="flex-row items-center justify-between w-full gap-[8px]">
                    <Typography className="text-[#B0B0B0] text-sm mt-[4px]">
                      min {minAmount} {currency}
                    </Typography>
                    <Typography className="text-[#B0B0B0] text-sm mt-[4px]">
                      max {maxAmount} {currency}
                    </Typography>
                  </Stack>
                </FormControl>

                {/* Account Email Input */}
                {/* <Stack className="w-full">
                <Typography className="text-[16px] mb-[8px] text-[#B0B0B0]">
                  Account email
                </Typography>
                <TextField
                  className="w-full bg-[#3D4A5D] rounded-full"
                  sx={{
                    "& .MuiInputBase-root": {
                      color: "white", // Input text color
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "50px", // Fully rounded input
                      "& fieldset": {
                        borderColor: "#B0B0B0", // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: "#4DA533", // Border color on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#4DA533", // Border color when focused
                        borderWidth: "2px", // Thicker border on focus
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "#B0B0B0", // Label color
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#4DA533", // Label color when focused
                    },
                    "& .MuiInputLabel-shrink": {
                      color: "white", // Label color when shrunk
                    },
                    "& .MuiOutlinedInput-input::placeholder": {
                      color: "#B0B0B0", // Placeholder color
                      opacity: 1, // Ensure full opacity for better visibility
                    },
                  }}
                  inputProps={{
                    className: "text-white px-[12px]", // Additional class for internal input customization
                  }}
                  value={accountEmail}
                  onChange={(e) => setAccountEmail(e.target.value)}
                  placeholder="Enter your email"
                  variant="outlined" // Use outlined variant to allow custom border styles
                />
              </Stack> */}

                {/* Deposit Button */}
                <Button
                  className="w-full bg-[#4DA533] min-h-[48px] text-white text-[16px] rounded-[4px] py-[12px] mt-[24px] hover:bg-[#3b8f29] disabled:bg-[#E2E8EE]"
                  onClick={() => {
                    console.log(
                      `Depositing ${selectedAmount || customAmount
                      } ${currency} to ${accountEmail}`
                    );
                    handleDepositCoin(customAmount, bonusMode, item);
                  }}
                  disabled={!customAmount}
                >
                  Deposit
                </Button>
              </Stack>
            )}
          </Stack>
        </Modal>
      )}
      <IFrameModal
        open={iFrameModalOpen}
        handleBack={() => {
          handleBack();
          setIFrameModalOpen(false);
        }}
        iFrameUrl={iFrameUrl}
      />
    </>
  );
};

export default DepositModal;
