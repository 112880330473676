import { TOP_SPORT } from "src/app/constants";
import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";
import toast from "react-hot-toast";
import {
  poker,
  sportbook_id,
  video_bingo,
  video_bingo_1,
  video_poker,
  virtual_games,
  virtual_sports,
} from "src/app/constants/casino-types";
import { Alert } from "@mui/material";

const api_domain = jwtServiceConfig.apiUrl;

export const getCasinoTypes = createAsyncThunk("settingApp/getCasinoTypes", async () => {
  const response = await axios.get(`${api_domain}casinoTypes`);
  let casino_types = response.data.casino_types;
  // let video_bingo_doubled = false;
  // let poker_doubled = false;
  // let virtual_game_doubled = false;

  // if (casino_types.some((t) => t.id === video_bingo) && casino_types.some((t) => t.id === video_bingo_1))
  //   video_bingo_doubled = true;
  // if (casino_types.some((t) => t.id === poker) && casino_types.some((t) => t.id === video_poker)) poker_doubled = true;
  // if (casino_types.some((t) => t.id === virtual_games) && casino_types.some((t) => t.id === virtual_sports))
  //   virtual_game_doubled = true;

  // casino_types = casino_types
  //   .filter((t) => !(video_bingo_doubled && t.id === video_bingo_1))
  //   .filter((t) => !(poker_doubled && t.id === video_poker))
  //   .filter((t) => !(virtual_game_doubled && t.id === virtual_sports));

  // return { casino_types, video_bingo_doubled, poker_doubled, virtual_game_doubled };
  return { casino_types: casino_types.filter((t) => t.id !== sportbook_id) };
});

export const getCompetitorSearch = createAsyncThunk("settingApp/getCompetitorSearch", async ({ langId, competitor }) => {
  const { data } = await axios.get(`${api_domain}search?lang=${langId}&search=${competitor}`);
  return data;
})

export const getPaymentURL = createAsyncThunk("settingApp/getPaymentURL", async ({ coin, amount, bonusMode }) => {
  try {
    if(coin == "" || amount == "" || parseFloat(amount) == 0) {
      toast.custom(
        <Alert severity="error">Input Amount</Alert>
      );
      return "Input Amount";
    }
    const { data } = await axios.post(`${api_domain}coinpayments/create-transaction`,{coin, amount: parseFloat(amount), bonusMode});
    return data;
  } catch (err) {
      toast.custom(
        <Alert severity="error">{err.response.data.user_error}</Alert>
      );
      return err?.response;
  }
})

export const getBankList = createAsyncThunk("settingApp/getBankList", async () => {
  try {
    const { data } = await axios.post(`${api_domain}maksipara/getBankList`);
    return data;
  } catch (err) {
      toast.custom(
        <Alert severity="error">{err.response.data.error}</Alert>
      );
      return err?.response;
  }
})


export const getWithdrawBankTransfer = createAsyncThunk(
  "settingApp/getWithdrawBankTransfer",
  async ({ amount, iban, bank }) => {
    try {
      // Validate all fields
      if (!amount || amount <= 0) throw new Error("Amount is required and must be greater than zero.");
      if (!iban) throw new Error("IBAN is required.");
      if (!bank) throw new Error("Bank selection is required.");

      const response = await axios.post(`${api_domain}maksipara/withdrawBankTransfer`, {
        amount,
        iban,
        bank
      });
      return response.data;
    } catch (err) {
      toast.custom(
        <Alert severity="error">{err.response?.data?.error || err.message}</Alert>
      );
      return err?.response;
    }
  }
);

export const getWithdrawJetpay = createAsyncThunk(
  "settingApp/getWithdrawJetpay",
  async ({ amount, iban, bank, data }) => {
    try {
      if (!amount || amount <= 0) throw new Error("Amount is required and must be greater than zero.");
      if (!iban) throw new Error("IBAN is required.");
      if (!bank) throw new Error("Bank selection is required.");

      const response = await axios.post(`${api_domain}jetpay/withdrawJetpay`, {
        amount,
        bank,
        iban,
        data,
      });
      toast.custom(
        <Alert severity="success">We've successfully sent your withdraw request.</Alert>
      );
      return response.data;
    } catch (err) {
      toast.custom(
        <Alert severity="error">{err.response?.data?.error || err.message}</Alert>
      );
      return err?.response;
    }
  }
);

export const getWithdrawPapara = createAsyncThunk(
  "settingApp/getWithdrawPapara",
  async ({ amount, account }) => {
    try {
      // Validate all fields
      if (!amount || amount <= 0) throw new Error("Amount is required and must be greater than zero.");
      if (!account) throw new Error("Papara account is required.");

      const response = await axios.post(`${api_domain}maksipara/withdrawPapara`, { amount, account });
      return response.data;
    } catch (err) {
      toast.custom(
        <Alert severity="error">{err.response?.data?.error || err.message}</Alert>
      );
      return err?.response;
    }
  }
);


export const getWithdrawPayfix = createAsyncThunk(
  "settingApp/getWithdrawPayfix",
  async ({ amount, account, data }) => {
    try {
      // Validate all fields
      if (!amount || amount <= 0) throw new Error("Amount is required and must be greater than zero.");
      if (!account) throw new Error("Payfix account is required.");

      const response = await axios.post(`${api_domain}maksipara/withdrawPayfix`, { amount, account });
      return response.data;
    } catch (err) {
      toast.custom(
        <Alert severity="error">{err.response?.data?.error || err.message}</Alert>
      );
      return err?.response;
    }
  }
);

export const getWithdrawPep = createAsyncThunk(
  "settingApp/getWithdrawPep",
  async ({ amount, account, data }) => {
    try {
      if (!amount || amount <= 0) throw new Error("Amount is required and must be greater than zero.");
      if (!account) throw new Error("Payfix account is required.");

      const response = await axios.post(`${api_domain}maksipara/withdrawPep`, { amount, account, data });
      return response.data;
    } catch (err) {
      toast.custom(
        <Alert severity="error">{err.response?.data?.error || err.message}</Alert>
      );
      return err?.response;
    }
  }
);

export const getWithdrawPaybol = createAsyncThunk(
  "settingApp/getWithdrawPaybol",
  async ({ amount, account }) => {
    try {
      if (!amount || amount <= 0) throw new Error("Amount is required and must be greater than zero.");
      if (!account) throw new Error("Payfix account is required.");

      const response = await axios.post(`${api_domain}maksipara/withdrawPaybol`, { amount, account });
      return response.data;
    } catch (err) {
      toast.custom(
        <Alert severity="error">{err.response?.data?.error || err.message}</Alert>
      );
      return err?.response;
    }
  }
);


export const getBankDepositPaymentURL = createAsyncThunk("settingApp/getBankDepositPaymentURL", async () => {
  try {
    const { data } = await axios.post(`${api_domain}maksipara/getDepositBankTransferUrl`);
    return data;
  } catch (err) {
      toast.custom(
        <Alert severity="error">{err.response.data.error}</Alert>
      );
      return err?.response;
  }
})

export const getDepositPaparaURL = createAsyncThunk("settingApp/getDepositPaparaUrlURL", async () => {
  try {
    const { data } = await axios.get(`${api_domain}maksipara/getDepositPaparaUrl`);
    return data;
  } catch (err) {
      toast.custom(
        <Alert severity="error">{err.response.data.error}</Alert>
      );
      return err?.response;
  }
})

export const getBonusList = createAsyncThunk("settingApp/getBonusList", async ({trigger}) => {
  try {
    const { data } = await axios.get(`${api_domain}bonus/list/${trigger}`);
    return data;
  } catch (err) {
      toast.custom(
        <Alert severity="error">{err.response.data.error}</Alert>
      );
      return err?.response;
  }
})

export const getPaymentGateways = createAsyncThunk("settingApp/getPaymentGateways", async () => {
  try {
    const { data } = await axios.post(`${api_domain}transaction/getGateways`);
    return data;
  } catch (err) {
      toast.custom(
        <Alert severity="error">{err.response.data.error}</Alert>
      );
      return err?.response;
  }
})

export const getWithdrawalCheck = createAsyncThunk("settingApp/getWithdrawalCheck", async () => {
  try {
    const { data } = await axios.post(`${api_domain}transaction/getWithdrawlCheck`);
    return data;
  } catch (err) {
      toast.custom(
        <Alert severity="error">{err.response.data.error}</Alert>
      );
      return err?.response;
  }
})

export const getDepositMaksiURL = createAsyncThunk("settingApp/getDepositMaksiURL", async ({gateway, bonusMode}) => {
  try {
    const { data } = await axios.get(`${api_domain}maksipara/getDepositMaksiURL/${gateway}/${bonusMode}`);
    return data;
  } catch (err) {
      toast.custom(
        <Alert severity="error">{err.response.data.user_error}</Alert>
      );
      return err?.response;
  }
})

export const getDepositURL = createAsyncThunk("settingApp/getDepositURL", async ({gateway, bonusMode, amount}) => {
  try {
    const { data } = await axios.get(`${api_domain}mpay/getDepositURL/${gateway}/${bonusMode}/${amount}`);
    return data;
  } catch (err) {
      toast.custom(
        <Alert severity="error">{err.response.data.user_error}</Alert>
      );
      return err?.response;
  }
})



export const getDepositJetpayURL = createAsyncThunk("settingApp/getDepositJetpayURL", async ({bonusMode}) => {
  try {
    const { data } = await axios.get(`${api_domain}jetpay/getDepositJetpayURL/${bonusMode}`);
    return data;
  } catch (err) {
      toast.custom(
        <Alert severity="error">{err.response.data.user_error}</Alert>
      );
      return err?.response;
  }
})

export const getCouponDetailByCode = createAsyncThunk("settingApp/getCouponDetailByCode", async ({code}) => {
  try {
    const { data } = await axios.get(`${api_domain}betDetail?code=${code}`);
    return data.coupons[0] || null;
  } catch (err) {
      toast.custom(
        <Alert severity="error">{err.response.data.error}</Alert>
      );
      return err?.response;
  }
})

export const getWithdraw = createAsyncThunk("settingApp/getWithdraw", async ({ coin, amount, address }) => {
  try {
    if(coin == "" || amount == ""  || address == "" || parseFloat(amount) == 0) {
      toast.custom(
        <Alert severity="error">Input Amount</Alert>
      );
      return "Input Amount";
    }
    const { data } = await axios.post(`${api_domain}coinpayments/create-withdrawal`,{coin, amount: parseFloat(amount), address});
    return data;
  } catch (err) {
      toast.custom(
        <Alert severity="error">{err.response.data.error}</Alert>
      );
      return err?.response;
  }
})

export const setForgotPassword = createAsyncThunk("settingApp/setForgotPassword", async ({email}) => {
  try {
    const { data } = await axios.post(`${api_domain}auth/forgot-password`, {email});
    toast.custom(
      <Alert severity="success">We've successfully sent the reset password link to your email.</Alert>
    );
    return data;
  } catch (err) {
      toast.custom(
        <Alert severity="error">{err.response.data.error}</Alert>
      );
      return err?.response;
  }
})

export const setPasswordReset = createAsyncThunk("settingApp/setPasswordReset", async ({email, newPassword, code}) => {
  try {
    const { data } = await axios.post(`${api_domain}auth/reset-password`, {email, new_password: newPassword, code});
    toast.custom(
      <Alert severity="success">Your password has been successfully reset.</Alert>
    );} catch (err) {
      toast.custom(
        <Alert severity="error">{err.response.data.error}</Alert>
      );
      return err?.response;
  }
})


export const getLogoAndSocialMedia = createAsyncThunk("settingApp/getLogoAndSocialMedia", async ({ coin, amount, address }) => {
  try {
    const { data } = await axios.get(`${api_domain}logo`);
    return data;
  } catch (err) {
      toast.custom(
        <Alert severity="error">{err.response.data.error}</Alert>
      );
      return err?.response;
  }
})

const initialState = {
  logo: "assets/images/logo/logo.png",
  twitter: "",
  discord: "",
  instagram: "",
  facebook: "",
  rightSideBarOpen: true,
  betType: "single",
  timerFilterValue: "All",

  selectedSport: TOP_SPORT,
  selectedLeagues: [],
  isLive: false,
  selectedFavLeagues: [],

  leaguesData: [],
  eventsData: [],

  loginModalVisible: false,
  signupModalVisible: false,

  cashierModalTab: "deposit",
  cashierModalVisible: false,
  authenticated: false,
  mobileLoginOpen: false,
  mobileSignupOpen: false,
  badgePosition: { x: 0, y: 0 },
  oddsFormat: "decimal",

  alreadyLoadedLeagues: [],
  lastClickedLeague: {},
  lastClickedFavLeague: {},

  casinoTypes: [],
  casinoTypesLoading: "idle",

  video_bingo_doubled: false,
  poker_doubled: false,
  virtual_game_doubled: false,

  casinoGame: { id: "all", name: "All Games" },
  pageForCasinoContent: 1,

  betslipVisible: false,

  detailedEvent: null,
  searchedEvents: [],
  inactive: false,
  affiliateId: '',
  menuList: []
};

const uiSlice = createSlice({
  name: "sportsApp/ui",
  initialState: initialState,
  reducers: {
    setRightSideBarOpen: (state, action) => ({
      ...state,
      rightSideBarOpen: action.payload,
    }),
    setMenuList: (state, action) => {
      state.menuList = action.payload;
    },
    setBetType: (state, action) => ({
      ...state,
      betType: action.payload,
    }),
    setAffiliateId: (state, action) => ({
      ...state,
      affiliateId:action.payload
    }),
    setSelectedSport: (state, action) => ({
      ...state,
      selectedSport: action.payload,
    }),
    setSelectedLeagues: (state, action) => ({
      ...state,
      selectedLeagues: action.payload,
    }),
    setLeaguesData: (state, action) => ({
      ...state,
      leaguesData: action.payload,
    }),
    setEventsData: (state, action) => ({
      ...state,
      eventsData: action.payload,
    }),
    removeSelectedLeagueId: (state, action) => ({
      ...state,
      selectedLeagues: state.selectedLeagues.filter((lea) => lea.id !== action.payload),
    }),
    toggleLive: (state) => ({
      ...state,
      isLive: !state.isLive,
    }),
    setCashierModalTab: (state, action) => ({
      ...state,
      cashierModalTab: action.payload,
    }),
    setCashierModalVisible: (state, action) => ({
      ...state,
      cashierModalVisible: action.payload,
    }),
    signin: (state) => ({
      ...state,
      authenticated: true,
    }),
    signout: (state) => ({
      ...state,
      authenticated: false,
    }),
    setMoblieLoginOpen: (state, action) => ({
      ...state,
      mobileLoginOpen: action.payload,
    }),
    setMobileSignupOpen: (state, action) => ({
      ...state,
      mobileSignupOpen: action.payload,
    }),
    setBadgePosition: (state, action) => {
      state.badgePosition = action.payload;
    },
    setOddsFormat: (state, action) => {
      state.oddsFormat = action.payload;
    },
    setLastClickedLeague: (state, action) => {
      state.lastClickedLeague = action.payload;
    },
    setTimerFilterValue: (state, action) => {
      state.timerFilterValue = action.payload;
    },
    setAlreadyLoadedLeagues: (state, action) => {
      state.alreadyLoadedLeagues = action.payload;
    },
    setLastClickedFavLeague: (state, action) => {
      state.lastClickedLeague = action.payload;
    },
    setSelectedFavLeagues: (state, action) => {
      state.selectedFavLeagues = action.payload;
    },
    setSignupModalVisible: (state, action) => {
      state.signupModalVisible = action.payload;
    },
    setLoginModalVisible: (state, action) => {
      state.loginModalVisible = action.payload;
    },
    setCasinoGame: (state, action) => {
      state.casinoGame = action.payload;
    },
    setPageForCasinoContent: (state, action) => {
      state.pageForCasinoContent = action.payload;
    },
    setBetslipVisbile: (state, action) => {
      state.betslipVisible = action.payload;
    },
    setDetailedEvent: (state, action) => {
      state.detailedEvent = action.payload;
    },
    setInactive: (state, action) => {
      state.inactive = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCasinoTypes.pending, (state) => {
        state.casinoTypesLoading = "loading";
      })
      .addCase(getCasinoTypes.fulfilled, (state, action) => {
        state.casinoTypesLoading = "fulfilled";
        // const { casino_types, video_bingo_doubled, poker_doubled, virtual_game_doubled } = action.payload;
        const { casino_types } = action.payload;
        state.casinoTypes = casino_types;
        // state.video_bingo_doubled = video_bingo_doubled;
        // state.poker_doubled = poker_doubled;
        // state.virtual_game_doubled = virtual_game_doubled;
      })
      .addCase(getCasinoTypes.rejected, (state) => {
        state.casinoTypesLoading = "rejected";
      })
      .addCase(getCompetitorSearch.fulfilled, (state, action) => {
        state.searchedEvents = action.payload;
      })
      .addCase(getLogoAndSocialMedia.fulfilled, (state, action) => {
        state.logo = action.payload.logo.logo;
        state.twitter = action.payload.logo.twitter;
        state.discord = action.payload.logo.discord;
        state.instagram = action.payload.logo.instagram;
        state.facebook = action.payload.logo.facebook;
      });
  },
});

export const {
  setRightSideBarOpen,
  setBetType,
  setSelectedSport,
  setSelectedLeagues,
  setLeaguesData,
  setEventsData,
  setAffiliateId,
  removeSelectedLeagueId,
  setCashierModalTab,
  setCashierModalVisible,
  signin,
  signout,
  toggleLive,
  setMoblieLoginOpen,
  setMobileSignupOpen,
  setBadgePosition,
  setOddsFormat,
  setLastClickedLeague,
  setTimerFilterValue,
  setAlreadyLoadedLeagues,
  setLastClickedFavLeague,
  setSelectedFavLeagues,
  setLoginModalVisible,
  setSignupModalVisible,
  setCasinoGame,
  setPageForCasinoContent,
  setBetslipVisbile,
  setDetailedEvent,
  setInactive,
  setMenuList
} = uiSlice.actions;

export const getRightSidebarOpen = ({ sportsApp }) => sportsApp.ui.rightSideBarOpen;
export const selectBetType = ({ sportsApp }) => sportsApp.ui.betType;
export const selectMenuList = ({ sportsApp }) => sportsApp.ui.menuList;
export const getselectedSport = ({ sportsApp }) => sportsApp.ui.selectedSport;
export const getSelectedLeagues = ({ sportsApp }) => sportsApp.ui.selectedLeagues;
export const getLeaguesData = ({ sportsApp }) => sportsApp.ui.leaguesData;
export const getEventsData = ({ sportsApp }) => sportsApp.ui.eventsData;
export const getIsLive = ({ sportsApp }) => sportsApp.ui.isLive;
export const getCashierModalVisible = ({ sportsApp }) => sportsApp.ui.cashierModalVisible;
export const getCashierModalTab = ({ sportsApp }) => sportsApp.ui.cashierModalTab;
export const selectAuthenticated = ({ sportsApp }) => sportsApp.ui.authenticated;
export const selectMobileLoginOpen = ({ sportsApp }) => sportsApp.ui.mobileLoginOpen;
export const selectMobileSignupOpen = ({ sportsApp }) => sportsApp.ui.mobileSignupOpen;
export const selectBadgetPosition = ({ sportsApp }) => sportsApp.ui.badgePosition;
export const selectUserOddsFormat = ({ sportsApp }) => sportsApp.ui.oddsFormat;
export const selectLastClickedLeague = ({ sportsApp }) => sportsApp.ui.lastClickedLeague;
export const selectAlreadyLoadedLeagues = ({ sportsApp }) => sportsApp.ui.alreadyLoadedLeagues;
export const selectLastClickedFavLeague = ({ sportsApp }) => sportsApp.ui.lastClickedFavLeague;
export const getSelectedFavLeagues = ({ sportsApp }) => sportsApp.ui.selectedFavLeagues;
export const selectTimerFilterValue = ({ sportsApp }) => sportsApp.ui.timerFilterValue;
export const selectCasinoTypes = ({ sportsApp }) => sportsApp.ui.casinoTypes;
export const selectVideoBingoDoubled = ({ sportsApp }) => sportsApp.ui.video_bingo_doubled;
export const selectPokerDoubled = ({ sportsApp }) => sportsApp.ui.poker_doubled;
export const selectAffiliateId = ({ sportsApp }) => sportsApp.ui.affiliateId;
export const selectVirtualGameDoubled = ({ sportsApp }) => sportsApp.ui.virtual_game_doubled;
export const selectLoginModalVisible = ({ sportsApp }) => sportsApp.ui.loginModalVisible;
export const selectSignupModalVisible = ({ sportsApp }) => sportsApp.ui.signupModalVisible;
export const selectCasinoGame = ({ sportsApp }) => sportsApp.ui.casinoGame;
export const selectPageForContent = ({ sportsApp }) => sportsApp.ui.pageForCasinoContent;
export const selectBetslipVisible = ({ sportsApp }) => sportsApp.ui.betslipVisible;
export const selectDetailedEvent = ({ sportsApp }) => sportsApp.ui.detailedEvent;
export const selectInactive = ({ sportsApp }) => sportsApp.ui.inactive;

export default uiSlice.reducer;
