import React, { useEffect } from 'react';

const DynamicScriptLoader = ({ scriptContent }) => {
  useEffect(() => {
    // Function to parse the script content and execute it
    const executeScript = (scriptText) => {
      const scriptElement = document.createElement('script');
      scriptElement.text = scriptText;
      document.body.appendChild(scriptElement);
      scriptElement.remove(); // Clean up the script element once it is executed
    };

    // Extracting the script from the provided string
    const scriptRegex = /<script[^>]*>([\s\S]*?)<\/script>/gi;
    let match;
    while ((match = scriptRegex.exec(scriptContent))) {
      executeScript(match[1]);
    }

    // Clean up function to ensure no memory leaks
    return () => {
      // Here you can handle any cleanup if necessary
    };
  }, [scriptContent]); // Re-run the effect only if the script content changes

  return null; // This component does not render anything
};

export default DynamicScriptLoader;
